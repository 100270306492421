import React, { useCallback, useEffect, useRef, useState } from "react";
import styles from "./woe.module.scss";
import { Modal } from "reactstrap";
import { StripeComponent } from "../payments/stripe";
import Iframe from "react-iframe";
import { useContext } from "react";
import { AuthContext } from "../../context/Auth/Auth";

export const Woe = () => {
  const [doingPurchase, setDoingPurchase] = useState(false);
  const [orderData, setOrderData] = useState(null);
  const { authCode } = useContext(AuthContext); 
  const listenersEnabled = useRef(false);

  const togglePurchaseModal = () => {
    setOrderData(null);
    setDoingPurchase(false);
  };

  useEffect(() => {
    if (authCode !== "") {
      // console.log("AUTH TOKEN [" + authCode + "]");
    }
  }, [authCode]);

  useEffect(() => {
    if (orderData !== null) {
      setDoingPurchase(true);
    }
  }, [orderData]);

  const onMessage = (evt) => {
    const allowedOrigins = process.env.REACT_APP_ALLOWED_ORIGINS.split(",");

    if (!allowedOrigins.includes(evt.origin)) return;

    const data = evt.data;

    if (data.func !== undefined && typeof window[data.func] == "function") {
      window[data.func].call(null, data.data);
    }
  };

  useEffect(() => {
    if (!listenersEnabled.current) {
      if (window.addEventListener) {
        window.addEventListener("message", onMessage, false);
      }
    }

    return () => {
      listenersEnabled.current = true;
      // window.removeEventListener("message", onMessage, false);
    };
  }, []);

  // eslint-disable-next-line
  const handlePurchaseButtonClicked = useCallback((data) => {
    /*

      'data': {
        'productId': productDataObj.productId,
        'productPrice': productDataObj.productPrice,
        'productName': productDataObj.productName,
        'orderId' : productOrderObj.orderId,
        'orderToken': productOrderObj.orderToken,
      }
    */

    setOrderData({
      productId: data.productId,
      productName: data.productName,
      productPrice: data.productPrice,
      orderId: data.orderId,
      orderToken: data.orderToken,
    });
    // setDoingPurchase(true);
  }, []);

  window["handlePurchaseButtonClicked"] = handlePurchaseButtonClicked;

  const handlePurchaseCompleted = () => {
    // Tell game to check order status

    const gameFrame = document.querySelector("#gameFrame");

    const postData = {
      func: "handlePurchaseCompleted",
      data: {
        orderId: orderData.orderId,
        orderToken: orderData.orderToken,
      },
    };

    setTimeout(() => {
      gameFrame.contentWindow.postMessage(
        postData,
        process.env.REACT_APP_WOE_URL
      );
      togglePurchaseModal();
    }, 1000);
  };

  useEffect(() => {
    if (orderData !== null) {
      setDoingPurchase(true);
    }
  }, [orderData]);

  return (
    <>
      {orderData && (
        <Modal isOpen={doingPurchase} toggle={() => togglePurchaseModal()}>
          <div className="modal-header justify-content-center">
            <button
              aria-hidden={true}
              className="close"
              onClick={() => togglePurchaseModal()}
              type="button"
            >
              <i className="now-ui-icons ui-1_simple-remove"></i>
            </button>
            <div>
              <h4 className="title title-up">
                ${orderData.productPrice / 100} (usd)
              </h4>
            </div>
            <div>
              <h3>{orderData.productName}</h3>
            </div>
          </div>
          <div className="modal-body">
            <StripeComponent
              purchaseComplete={handlePurchaseCompleted}
              orderData={orderData}
            />
          </div>
        </Modal>
      )}
      {authCode !== undefined && authCode !== "" && (
        <Iframe
          id="gameFrame"
          className={styles["game-frame"]}
          allow={"autoplay"}
          url={`${process.env.REACT_APP_WOE_URL}?authCode=${authCode}`}
        />
      )}
    </>
  );
};
