import React from "react";
import axios from "axios";

const ApiContext = React.createContext({ userData: {} });

const ApiProvider = ({ children }) => {
  const baseUrl = process.env.REACT_APP_PSP_API_URL;
  const tokenRefreshUrl = baseUrl + "/api/auth/refresh";

  const instance = axios.create({
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  });

  instance.interceptors.response.use(
    (res) => {
      return res;
    },
    async (err) => {
      const orig = err.config;
      if (err.response.status === 401 && !orig._retry) {
        orig._retry = true;

        try {
          await instance.post(tokenRefreshUrl);
          return instance(orig);
        } catch (_err) {
          return Promise.reject(_err);
        }
      } else {
        return Promise.reject(err.response);
      }
    }
  );

  const contextExport = {
    api: instance,
  };

  return (
    <ApiContext.Provider value={contextExport}>{children}</ApiContext.Provider>
  );
};

export { ApiContext, ApiProvider };
