import React from "react";

// reactstrap components
import {
  Button,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components

function FooterSocial() {
  return (
    <>
      <footer className="footer">
        <Container>
          <div className="content">
            <Row>
              <Col md="3">
                <a href="/">
                  <h5>Glaswyll Gaming Center</h5>
                </a>
                <p>Jump in today!</p>
              </Col>
              <Col md="2">
                <h5>About</h5>
                <ul className="links-vertical">
                  <li>
                    <a href="/about">About Us</a>
                  </li>
                  <li>
                    <a href="/contact">Contact Us</a>
                  </li>
                </ul>
              </Col>
              <Col md="2">
                <h5>Games</h5>
                <ul className="links-vertical">
                  <li>
                    <a href="/games">Games</a>
                  </li>
                  <li>
                    <a href="/link">Link Accounts</a>
                  </li>
                  <li>
                    <a href="/host-signup">Host your game</a>
                  </li>
                  <li>
                    <a href="/support" onClick={(e) => e.preventDefault()}>
                      Customer Support
                    </a>
                  </li>
                </ul>
              </Col>
              <Col md="2">
                <h5>Legal</h5>
                <ul className="links-vertical">
                  <li>
                    <a href="/terms">Terms &amp; Conditions</a>
                  </li>
                </ul>
              </Col>
              <Col md="3">
                <h5>Subscribe to Newsletter</h5>
                <p>
                  Join our newsletter and get news in your inbox every week! We
                  hate spam too, so no worries about this.
                </p>
                <Form action="" className="form form-newsletter" method="">
                  <FormGroup>
                    <Input placeholder="Your Email..." type="email"></Input>
                  </FormGroup>
                  <Button
                    className="btn-icon btn-round"
                    color="info"
                    name="button"
                    type="button"
                  >
                    <i className="now-ui-icons ui-1_email-85"></i>
                  </Button>
                </Form>
              </Col>
            </Row>
          </div>
          <hr></hr>
          <ul className="social-buttons">
            <li>
              <Button
                className="btn-icon btn-neutral mr-1"
                color="twitter"
                href="https://twitter.com/glaswyllgaming"
                size="lg"
              >
                <i className="fab fa-twitter"></i>
              </Button>
            </li>
            <li>
              <Button
                className="btn-icon btn-neutral mr-1"
                color="facebook"
                href="#pablo"
                onClick={(e) => e.preventDefault()}
                size="lg"
              >
                <i className="fab fa-facebook-square"></i>
              </Button>
            </li>
            <li>
              <Button
                className="btn-icon btn-neutral mr-1"
                color="dribbble"
                href="#pablo"
                onClick={(e) => e.preventDefault()}
                size="lg"
              >
                <i className="fab fa-dribbble"></i>
              </Button>
            </li>
            <li>
              <Button
                className="btn-icon btn-neutral mr-1"
                color="google"
                href="#pablo"
                onClick={(e) => e.preventDefault()}
                size="lg"
              >
                <i className="fab fa-google-plus"></i>
              </Button>
            </li>
            <li>
              <Button
                className="btn-icon btn-neutral"
                color="youtube"
                href="#pablo"
                onClick={(e) => e.preventDefault()}
                size="lg"
              >
                <i className="fab fa-youtube"></i>
              </Button>
            </li>
          </ul>
          <div className="copyright pull-center" id="copyright">
            Copyright © {new Date().getFullYear()} Glaswyll Entertainment, LLC.
            All Rights Reserved.
          </div>
        </Container>
      </footer>
    </>
  );
}

export default FooterSocial;
