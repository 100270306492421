import React, { useState } from "react";

const AccountContext = React.createContext();

const AccountProvider = ({ children }) => {
  const [linkDialogOpen, setLinkDialogOpen] = useState(false);

  const ToggleAccountLinkDialog = () => {
    setLinkDialogOpen(!linkDialogOpen);
  };

  const contextExport = {
    ToggleAccountLinkDialog,
    linkDialogOpen,
  };

  return (
    <AccountContext.Provider value={contextExport}>
      {children}
    </AccountContext.Provider>
  );
};

export { AccountContext, AccountProvider };
