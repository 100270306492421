import styles from './Terms.module.scss';

const Terms = () => {

  return(
    <div className={styles['terms-container']}>
      <div className={styles['terms-content']}>
        <p>Welcome to Glaswyll Gaming Center (hereinafter referred to as "GGC"). These terms and conditions (the "Terms") outline the rules and regulations for your use of the website located at https://ggc.glaswyll.com (the "Website"). By accessing or using the Website, you agree to comply with these Terms. If you do not agree with any part of these Terms, please refrain from using the Website.</p>

        <h3>1. Acceptance of Terms</h3>

        <p>By accessing or using the Website, you acknowledge that you have read, understood, and agreed to be bound by these Terms. If you do not agree with these Terms, please do not use the Website.</p>

        <h3>2. Changes to Terms</h3>

        <p>GGC reserves the right to modify, update, or replace these Terms at any time without prior notice. Continued use of the Website after any changes signifies your acceptance of the modified Terms.</p>

        <h3>3. User Eligibility</h3>

        <p>You must be at least 13 years old to use the Website. By using the Website, you represent and warrant that you have the legal capacity to enter into this agreement and to abide by these Terms.</p>

        <h3>4. User Accounts</h3>

        <p>4.1. You may need to create a user account to access certain features of the Website. You are responsible for maintaining the confidentiality of your account information and for all activities that occur under your account.</p>

        <p>4.2. You agree to provide accurate, current, and complete information during the registration process and to update such information to keep it accurate, current, and complete.</p>

        <h3>5. User Conduct</h3>

        <p>5.1. You agree to use the Website in compliance with all applicable laws and regulations. You will not engage in any unlawful, offensive, harmful, or abusive behavior while using the Website.</p>

        <p>5.2. You will not upload, distribute, or transmit any content that infringes on the intellectual property rights or privacy of others.</p>

        <h3>6. Content Ownership</h3>

        <p>All content available on the Website, including text, graphics, images, videos, and other materials, is the property of GGC or its licensors and is protected by copyright and other intellectual property laws.</p>

        <h3>7. Limitation of Liability</h3>

        <p>GGC shall not be liable for any indirect, incidental, special, consequential, or punitive damages arising out of or relating to your use of the Website.</p>

        <h3>8. Indemnification</h3>

        <p>You agree to indemnify, defend, and hold harmless GGC and its affiliates, officers, directors, employees, and agents from and against any and all claims, liabilities, damages, losses, costs, expenses, or fees arising out of or relating to your use of the Website or your violation of these Terms.</p>

        <h3>9. Governing Law</h3>

        <p>These Terms shall be governed by and construed in accordance with the laws of [Jurisdiction], without regard to its conflict of law principles.</p>

        <h3>10. Contact Information</h3>

        <p>If you have any questions or concerns about these Terms, please contact us at [contact email].</p>

        <p>By using the Glaswyll Gaming Center Website, you acknowledge that you have read, understood, and agreed to these Terms and Conditions. Thank you for choosing GGC as your gaming platform.</p>
      </div>
    </div>
  );
};

export default Terms;