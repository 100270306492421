import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
// import { reactLocalStorage } from "reactjs-localstorage";
import { ApiContext } from "../Api/Api";

const AuthContext = React.createContext({ userData: {} });

const AuthProvider = ({ children }) => {
  const { api } = useContext(ApiContext);
  const [isNewUser, setIsNewUser] = useState(true);
  const [userData, setUserData] = useState({});
  const [authCode, setAuthCode] = useState("");
  // eslint-disable-next-line
  const [gettingAuthCode, setGettingAuthCode] = useState(false);
  const authCodeSet = useRef(false);
  //   const [cookies, setCookie, removeCookie] = useCookies(["userData"]);

  useEffect(() => {}, [authCode]);

  const FetchAuthData = useCallback(() => {
    const baseUrl = process.env.REACT_APP_PSP_API_URL;
    const apiUrl = baseUrl + "/api/auth/session";

    // Make a request for a user with a given ID
    api
      .get(apiUrl)
      .then(function (response) {
        // handle success
        if (response.data !== undefined && response.data !== {}) {
          const data = response.data;

          if (data["newUser"] !== undefined) {
            setIsNewUser(data["newUser"]);
          }

          const userData = data["userData"];

          if (data["authCode"] !== undefined) {
            setAuthCode(data["authCode"]);
          }

          setUserData(userData);
        }
      })
      .catch(function (error) {
        // handle error
        console.log("error");
        console.log(error);
      })
      .then(function () {
        // always executed
      });
  }, [api]);

  useEffect(() => {
    if (!authCodeSet.current) {
      setGettingAuthCode(true);
      FetchAuthData();
    }

    return () => (authCodeSet.current = true);
  }, [FetchAuthData]);

  const GetNewUser = () => {
    return isNewUser;
  };

  const GetUserData = () => {
    return userData;
  };

  const GetAuthCode = () => {
    return authCode;
  };

  const contextExport = {
    GetNewUser,
    GetUserData,
    GetAuthCode,
    FetchAuthData,
    authCode,
  };

  return (
    <AuthContext.Provider value={contextExport}>
      {children}
    </AuthContext.Provider>
  );
};

const Auth = () => {
  useEffect(() => {}, []);
};

export { AuthContext, AuthProvider };
export default Auth;
