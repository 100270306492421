import React, { useContext } from "react";
import { ApiContext } from "../Api/Api";

const AccountLinkContext = React.createContext({});

const AccountLinkProvider = ({ children }) => {
  const { api } = useContext(ApiContext);

  const LinkFacebookAccount = (id) => {
    console.log("Linking Facebook account ID [" + id + "]");
    const baseUrl = process.env.REACT_APP_PSP_API_URL;
    const apiUrl = baseUrl + "/api/account/link";
    const payload = {
      provider: "facebook",
      source_id: id,
    };

    // Make a request for a user with a given ID
    api.post(apiUrl, payload).then(
      (response) => {
        console.log("Account link response:");
        console.log(response);
      },
      (err) => {
        console.log("Error with account link call:");
        console.log(err.data);
      }
    );
  };

  const LinkGoogleAccount = (id) => {
    console.log("Linking Facebook account ID [" + id + "]");
    const baseUrl = process.env.REACT_APP_PSP_API_URL;
    const apiUrl = baseUrl + "/api/account/link";
    const payload = {
      provider: "google",
      source_id: id,
    };

    // Make a request for a user with a given ID
    api
      .post(apiUrl, payload)
      .then((response) => {
        console.log("Account link response:");
        console.log(response);
      })
      .catch((err) => {
        console.log("Error with account link call:");
        console.log(err);
      });
  };

  const contextExport = {
    LinkFacebookAccount,
    LinkGoogleAccount,
  };

  return (
    <AccountLinkContext.Provider value={contextExport}>
      {children}
    </AccountLinkContext.Provider>
  );
};

export { AccountLinkContext, AccountLinkProvider };
