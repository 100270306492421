import { BrowserRouter, Routes, Route } from "react-router-dom";

// eslint-disable-next-line
import devTools from "devtools-detect";

// styles
import "./assets/css/bootstrap.min.css";
import "./assets/scss/now-ui-kit.scss";
import "./assets/demo/demo.css";
import "./assets/demo/react-demo.css";
import "./assets/demo/nucleo-icons-page-styles.css";

import { AuthProvider } from "./context/Auth/Auth";

// import styles from "./App.module.scss";
import { Woe } from "./components/woe";
import Home from "./pages/Home";
import Payment from "./pages/Payment";
import Layout from "./pages/Layout";
import { useEffect, useState } from "react";
import TermsPage from "./pages/Terms/Terms";
import { AccountLinkProvider } from "./context/AccountLink/AccountLink";
import { ApiProvider } from "./context/Api/Api";
import { AccountProvider } from "./context/Account/Account";

function App() {
  const [devToolsOpen, setDevToolsOpen] = useState(false);

  useEffect(() => {
    window.addEventListener("devtoolschange", (e) => {
      setDevToolsOpen(e.detail.isOpen);
    });
  }, []);

  useEffect(() => {
    if (devToolsOpen) {
      // eslint-disable-next-line no-console
      console.log(
        `       _____  _                               _  _ 
      / ____|| |                             | || |
     | |  __ | |  __ _  ___ __      __ _   _ | || |
     | | |_ || | / _\` |/ __|\\ \\ /\\ / /| | | || || |
     | |__| || || (_| |\\__ \\ \\ V  V / | |_| || || |
      \\_____||_| \\__,_||___/  \\_/\\_/   \\__, ||_||_|
                                        __/ |      
                                       |___/       `
      );

      window.removeEventListener("devtoolschange", null);
    }
  }, [devToolsOpen]);

  return (
    <ApiProvider>
      <AuthProvider>
        <AccountProvider>
          <AccountLinkProvider>
            <BrowserRouter>
              <Routes>
                <Route path="/" element={<Layout />}>
                  <Route index element={<Home />} />
                  <Route path="terms" element={<TermsPage />} />
                  <Route path="payment" element={<Payment />} />
                  <Route path="game/wings-of-elysium" element={<Woe />} />
                </Route>
              </Routes>
            </BrowserRouter>
          </AccountLinkProvider>
        </AccountProvider>
      </AuthProvider>
    </ApiProvider>
  );
}

export default App;
