import { PayPalComponent } from "../../components/payments/paypal";
import { StripeComponent } from "../../components/payments/stripe";

const Payment = () => {
  return (
    <div>
      <PayPalComponent />
      <hr />
      <StripeComponent />
    </div>
  );
};

export default Payment;
