import { Outlet } from "react-router-dom";
import { AccountLinkDialog } from "../../components/account-link-dialog";

const Layout = () => {
  return (
    <>
      <Outlet />
      <AccountLinkDialog />
    </>
  );
};

export default Layout;
