import { useCallback, useContext, useEffect } from "react";
import { Modal } from "reactstrap";
import { AccountLinkContext } from "../../context/AccountLink/AccountLink";

export const AccountLinkDialog = () => {
  const {linkDialogOpen, ToggleAccountLinkDialog, LinkFacebookAccount, LinkGoogleAccount} = useContext(AccountLinkContext);

  const FBLink = () => {
    window.FB.login(function(response) {
      console.log("FB LOGIN RESPONSE");
      console.log(response);
      if (response.authResponse) {
        window.FB.api('/me', function(response) {
          if(response.error){
            return;
          }

          if(response.id !== undefined){
            console.log("RESPONSE");
            console.log(response);
            LinkFacebookAccount(response.id);
          }
        });
      } else {
        console.log('User cancelled login or did not fully authorize.');
      }
    }, {scope: 'email,public_profile'});
  };

  const GoogleLink = () => {
    window.google.accounts.id.prompt();
  }

  const GoogleLoginResponse = useCallback((response) => {
    const cred = response.credential;
    const credPieces = cred.split(".");
    const sourceId = JSON.parse(atob(credPieces[1])).sub;
    // console.log(credPieces[1]);
    LinkGoogleAccount(sourceId);
  },[LinkGoogleAccount]);

  useEffect(() => {
    window.onload = function () {
      window.google.accounts.id.initialize({
        client_id: '1082771119229-hc445miv3tcuel4dbna6f7o8ubjnqoso.apps.googleusercontent.com',
        callback: (response) => {
          GoogleLoginResponse(response);
        },
      });
    };
  }, [GoogleLoginResponse]);

  return(<>
    <Modal
      isOpen={linkDialogOpen}
      toggle={ToggleAccountLinkDialog}
    >
      <div>
        <ul>
          <li>
            <button onClick={(e) => {
              e.preventDefault();
              FBLink();
            }}>Facebook</button>
          </li>
          <li>
            <button onClick={(e) => {
              e.preventDefault();
              GoogleLink();
            }}>Google</button>
            {/* <div id="g_id_onload"
              data-client_id="1082771119229-hc445miv3tcuel4dbna6f7o8ubjnqoso.apps.googleusercontent.com"
              data-context="signin"
              data-ux_mode="popup"
              data-callback="SigninCompleted"
              data-auto_prompt="false">
            </div>

            <div class="g_id_signin"
              data-type="icon"
              data-shape="circle"
              data-theme="filled_black"
              data-text="signin_with"
              data-size="large">
            </div> */}
          </li>
          <li>Twitter</li>
          <li>Apple</li>
          <li>Line</li>
        </ul>
      </div>
    </Modal>
  </>);
}
