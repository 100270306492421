/*eslint-disable*/
import React from "react";
import { useContext } from "react";
import { Link } from "react-router-dom";
// reactstrap components
import {
  Button,
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  NavbarBrand,
  Navbar,
  NavItem,
  Nav,
  Container,
  UncontrolledTooltip,
} from "reactstrap";
import { AuthContext } from "../../context/Auth/Auth";
import { AccountLinkContext } from "../../context/AccountLink/AccountLink";

function GGCNavbar() {
  const [collapseOpen, setCollapseOpen] = React.useState(false);

  const { GetNewUser, GetUserData } = useContext(AuthContext);
  const { ToggleAccountLinkDialog } = useContext(AccountLinkContext);

  const newUser = GetNewUser();
  const userData = GetUserData();
  const welcomePhrase = newUser
    ? "Welcome, new person!"
    : `Welcome back ${userData["userName"]}, we remember you!`;

  return (
    <>
      {collapseOpen ? (
        <div
          id="bodyClick"
          onClick={() => {
            document.documentElement.classList.toggle("nav-open");
            setCollapseOpen(false);
          }}
        />
      ) : null}
      <Navbar className="bg-white fixed-top" expand="lg">
        <Container>
          <div className="navbar-translate">
            <NavbarBrand to="/" tag={Link} id="navbar-brand">
              Glaswyll Gaming Center
              <br />
              <span style={{ textTransform: "none" }}>{welcomePhrase}</span>
            </NavbarBrand>
            <button
              onClick={() => {
                document.documentElement.classList.toggle("nav-open");
                setCollapseOpen(!collapseOpen);
              }}
              aria-expanded={collapseOpen}
              className="navbar-toggler"
            >
              <span className="navbar-toggler-bar top-bar"></span>
              <span className="navbar-toggler-bar middle-bar"></span>
              <span className="navbar-toggler-bar bottom-bar"></span>
            </button>
          </div>
          <Collapse isOpen={collapseOpen} navbar>
            <Nav className="ml-auto" id="ceva" navbar>
              <UncontrolledDropdown nav>
                <DropdownToggle
                  caret
                  color="default"
                  data-toggle="dropdown"
                  href="#pablo"
                  id="navbarDropdownMenuLink1"
                  nav
                  onClick={(e) => e.preventDefault()}
                >
                  <i className="now-ui-icons tech_controller-modern"></i>
                  <p>Games</p>
                </DropdownToggle>
                <DropdownMenu aria-labelledby="navbarDropdownMenuLink1" right>
                  <DropdownItem to="/game/wings-of-elysium" tag={Link}>
                    <i className="now-ui-icons objects_diamond"></i>
                    Wings of Elysium
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
              <NavItem>
                <Button
                  className="nav-link"
                  color="info"
                  onClick={() => {
                    ToggleAccountLinkDialog();
                  }}
                >
                  <p>Account</p>
                </Button>
              </NavItem>
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default GGCNavbar;
