import { Content } from "../Content";
import Terms from "../../components/Content/Terms/Terms";

const TermsPage = () => {
  return(
    <Content>
      <Terms />
    </Content>
  );
};

export default TermsPage;