import React, { useEffect, useRef, useState, useContext} from "react";

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from "./CheckoutForm";
import { ApiContext } from "../../../context/Api/Api";

const stripePromise = loadStripe(
  "pk_test_51NeNXkJOcvlg8XNTbY0SCSslxHUb4K9Rl2T3kZS17EZh69Jo9LTveY3L593PAozGEPS9tibKmDnrJmfvd59hlJMl00pQBCWPYm"
);

export const StripeComponent = ({orderData, purchaseComplete}) => {
  const { api } = useContext(ApiContext);

  // eslint-disable-next-line
  const [clientSecret, setClientSecret] = useState("");

  const orderDataSet = useRef(false);

  useEffect(() => {
    if(orderDataSet.current !== true){
      const baseUrl = process.env.REACT_APP_PSP_API_URL;
      const apiUrl = baseUrl + "/api/payments/stripe/create-payment-intent";
      // const intentData = JSON.stringify({ items: [productData]});
      const postConfig = {
        headers: {
          "Content-Type": "application/json",
        }
      };

      api
        .post(apiUrl, JSON.stringify(orderData), postConfig)
        .then((res) => {
          const response = res;
          setClientSecret(response.data.clientSecret);
        });
        // .then((data) => setClientSecret(data.clientSecret));
    }

    return () => {orderDataSet.current = true};
  }, [orderData, api]);

  const options = {
    clientSecret
  };

  return (
    <div>
      {clientSecret && (
        <Elements stripe={stripePromise} options={options}>
          <CheckoutForm purchaseComplete={purchaseComplete} />
        </Elements>
      )}
    </div>
  );
};
