import React from "react";

// reactstrap components
import { Container, Row, Col } from "reactstrap";

// core components
// import LandingPageHeader from "../../components/Headers/LandingPageHeader.js";

import styles from "./Home.module.scss";
import SectionGameFull from "../../views/sections-sections/SectionGameFull.js";
import { Content } from "../Content";

const Home = () => {
  React.useEffect(() => {
    document.body.classList.add("landing-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("landing-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);
  return (
    <>
      <Content>
        <div className="wrapper">
          <div
            className={`section section-about-us ${styles["section-header"]}`}
          >
            <Container>
              <Row>
                <Col className="ml-auto mr-auto text-center" md="8">
                  <img
                    alt="Glaswyll Gaming Center"
                    className="img"
                    src={require("../../assets/img/ggc/GGC_logo.png")}
                  ></img>
                </Col>
              </Row>
              <div className="separator separator-info"></div>
            </Container>
          </div>
          <SectionGameFull />
        </div>
      </Content>
    </>
  );
};

export default Home;
