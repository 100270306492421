/*eslint-disable*/
import React from "react";

// reactstrap components
import { CardTitle, Container, Row, Col } from "reactstrap";

// core components

function SectionGameFull() {
  return (
    <>
      <div className="cd-section" id="projects">
        <div
          className="projects-3"
          data-background-color="black"
          style={{
            backgroundImage:
              "url(" + require("../../assets/img/woe-bg.png") + ")",
            backgroundPosition: "center",
          }}
        >
          <Container>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="8">
                <h6 className="category text-muted">Our work</h6>
                <h2 className="title">Some of Our Awesome Projects - 3</h2>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
}

export default SectionGameFull;
