import FooterGGC from "../../components/Footers/FooterGGC.js";
import GGCNavbar from "../../components/Navbars/GGCNavbar.js";

const Content = ({children}) => {
  return (
    <>
      <div><GGCNavbar /></div>
      <div>
        {children}
      </div>
      <div>
        <FooterGGC />
      </div>
    </>
  );
};

export { Content };